import { Component, OnInit } from '@angular/core';
import {HttpService} from '../services/http/http.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private projectMap: any;
  public key: string  = undefined;

  public textBanner: String = null;
  public dataBanner: any[] = [];
  public nbreBanner: number = 0;
  public listBanners: any[] = [];

  constructor(private http: HttpService, private route: ActivatedRoute) { }

  private scrollBanner(index, size){
    this.textBanner = this.dataBanner[index].DESCRIPTION_BANNER;
    setTimeout(() => {
        this.scrollBanner((index + 1) % size, size);
    },15000);

  }

  private processBanner(){

    this.http.get('home', {get: 'banner', now: (new Date()).getTime()}).subscribe((data) => {
      const size = data.length;
      this.dataBanner = data;
      this.nbreBanner = data.length;
      // console.log(this.dataBanner);
      // console.log(this.dataBanner[0].DESCRIPTION_BANNER);
      if (size === 0){
        return;
      }
      this.scrollBanner(0, size);

    });
  }

  initBanner(): void {
    this.http.get('home', {get: 'banner', now: (new Date()).getTime()}).subscribe((data) => {
      const sizebanner = data.length;
      this.listBanners = data;
    });
  }
  get listBanner(){
    console.log(this.dataBanner);
    return this.dataBanner;
  }


  initProjectInfo(): void {
    this.http.get('home', {get: 'info_projects'}).subscribe( data => {
      this.projectMap = data;
    });
    /*this.projectMap = {
      total: 0,
      notStart: 172,
      started: 898,
      pending: 32,
      done: 207
    };*/
  }

  get totalProject(): number {
    if (this.projectMap == null){
      return 0;
    }
    return this.projectMap.total;
  }

  get notStartProject(): number {
    if (this.projectMap == null){
      return 0;
    }
    return this.projectMap.notStart;
  }

  get startedProject(): number {
    if (this.projectMap == null){
      return 0;
    }
    return this.projectMap.started;
  }

  get pendingProject(): number {
    if (this.projectMap == null){
      return 0;
    }
    return this.projectMap.pending;
  }

  get doneProject(): number {
    if (this.projectMap == null){
      return 0;
    }
    return this.projectMap.done;
  }

  private openLogin(id: string) {
    this.key = id;
  }

  ngOnInit(): void {

    const id = this.route.snapshot.paramMap.get('id');

    if (id){
      this.openLogin(id);
    }

    this.initProjectInfo();

    this.processBanner();

    // this.initBanner();
    console.log(this.listBanner);
    console.log(this.dataBanner);
  }


}
