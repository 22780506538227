import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../services/http/http.service';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRouteSnapshot, Route, Router} from '@angular/router';
import {formatDate} from '@angular/common';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private readonly space: string;
  public loading = false;
  errorLogin: any = false;
  private isReset = false;
  token = 0;

  public loginForm: FormGroup =  new FormGroup({});
  public resetForm: FormGroup =  new FormGroup({});
  public confirmForm: FormGroup =  new FormGroup({});

  @Output() closeLoginPage = new EventEmitter<boolean>();
  @Input() key = null;
 isPasswordReset = false;
  public resetSended = false;
  loadingGlobal = true;
  user: any;
  expire: number;

  initFail :boolean= true;
  errorLoginGlobal :any= {id:1};

  constructor(private http: HttpService, private translate: TranslateService, private route: Router) {
    this.token = (new Date()).getTime();
    this.loading  = false;
    this.space = this.http.espace;
    // this.changeLang((localStorage.getItem('lang_ficr') || navigator.language || 'f').substr(0, 2));
  }

  closeLogin(): void {
    if (this.isPasswordReset){
      this.route.navigate(['home']);
      return;
    }
    this.closeLoginPage.emit(false);
  }
  get email(): AbstractControl{
    return this.loginForm.get('email') || new FormControl();
  }

  get password(): AbstractControl {
    return (this.isPasswordReset ? this.confirmForm : this.loginForm).get('password') || new FormControl();
  }

  get confirmPassword(): AbstractControl {
    return this.confirmForm.get('confirmPassword') || new FormControl();
  }

  get resetEmail(): AbstractControl {
    return this.resetForm.get('email') || new FormControl();
  }

  set resetPassword(value: boolean){
    this.isReset = value;
  }
  get resetPassword(): boolean{
    return this.isReset;
  }

  confirmReset(){
    this.errorLogin = false;
    if (this.password.invalid || this.confirmPassword.invalid){
      return;
    }

    if (this.password.value !== this.confirmPassword.value){
      this.errorLogin = true;
      return;
    }
    this.http.post('reset_password', {method: 1, psw: this.password.value, action: 'change_psw', k: this.key }).subscribe((info: any) => {
    });

    this.resetSended = true;
  }


  login(): void {
    if (this.loading) {
      return;
    }
    this.errorLogin = false;
    if (this.email.invalid || this.password.invalid) {
      return;
    }
    this.loading = true;
    this.http.post('web_server', {
      action: 'l',
      e: this.space,
      l: this.translate.currentLang,
      m: this.email.value,
      method: 1,
      now: this.token,
      p: this.password.value,
      t: 1,
      li_bksb: 'Connexion',
      t_bksb: `${this.token}`,
    }).subscribe((data: any) => {
      if (!data){
        this.loading = false;
        return;
      }
      if (!data.l) {
        this.errorLogin = data;
        this.loading = false;
        return;
      }
      if (window.localStorage) {
        localStorage.setItem('token', data.k);
      }
      document.location.href = this.http.urlClient + '?' + data.k;
    }, (error) => {
      this.errorLogin = true;
      this.loading = false;
    });

  }

  forgotPassword(): void {
    this.resetPassword = true;
    // localStorage.setItem('RESET_KEY', btoa(this.space + ':PROD'));
    // location.href = './password_reset';
  }

  sendReset(): void {
    this.http.post('reset_password', {
      method: 1, action: 'reset', t_bksb: 1, email: this.resetEmail.value,
      lang: this.translate.currentLang, link:  (window.location.href.split('/'))[2]
    }).subscribe();

    this.resetSended = true;

  }

  ngOnInit(): void {
    this.loginForm =  new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      password: new FormControl(null, [Validators.required])
    });
    this.resetForm =  new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required])
    });

    this.confirmForm =  new FormGroup({
      confirmPassword: new FormControl(null, [Validators.minLength(8), Validators.required]),
      password: new FormControl(null, [Validators.required, Validators.minLength(8)])
    });

    //verifie espace
    this.checkSpace();



    const email = this.loginForm.get('email');

    if (email !== null){
      email.setValidators([Validators.email, Validators.required]);
    }

    if (!this.key){
        this.loadingGlobal = false;
        return;
    }

    this.isPasswordReset = true;

    this.http.get('reset_password', {method: 2, get: 'info', k: this.key }).subscribe((info: any) => {
      this.loadingGlobal = false;
      this.user = info.email;
      if (info.expire) {
          this.expire = 1;
          return;
        }
      this.expire = 0;
    });



  }

  checkSpace(){
    this.loading = true;
    this.http.get('Connexion', {get: 'space_status'}).subscribe((data: any) => {
      this.loading = false;
      const currentValue = parseInt((data.id || '').toString(), 10);
      let dateObj =null;
      try {
        dateObj= formatDate(data.date, 'longDate',  this.translate.currentLang);

      } catch (error) {
        dateObj= formatDate(data.date, 'longDate',"en");
        console.log("formatDate Error = "+error);

      }

      this.translate.get(['HOME.ERROR_EXPIRED', 'HOME.ERROR_LOCK'],{'date': dateObj}).subscribe((_transData: any) => {
        const reps = _transData;
        console.log(reps);


        // 1 =Will Expire && // -1 = Validate
        switch (currentValue) {
          case 1:
            this.errorLoginGlobal = {
              id: 1,
              msg: _transData['HOME.ERROR_EXPIRED']
            };
            break;
          case -1:
            this.errorLoginGlobal = {
              id: -1,
              msg: _transData['HOME.ERROR_LOCK']
            };
            break;
          default:
            this.initFail = false;
            break;
        };
        console.log(this.errorLoginGlobal);
        // this.emailInput.nativeElement.focus();
      });
    });
  }

}
