import { Pipe, PipeTransform } from '@angular/core';

interface Document {
  author: string;
  dateModified: string;
  id: number;
  isFile: boolean;
  name: string;
  theme: string;
  type_doc: string;
  type: string;
  allSector: string;
  allSourcefin: string;

}

@Pipe({
  name: 'tableDocsFilter'
})
export class TableDocsFilterPipe implements PipeTransform {

  transform(list: any, filters: Object) {
    const keys = Object.keys(filters).filter(key => filters[key]);
    const filterDocument = document => keys.every(key => document[key] === filters[key]);
    return keys.length ? list.filter(filterDocument) : list;

  }

}
