import { Component, OnInit } from '@angular/core';
import {HttpService} from '../services/http/http.service';
import {Control, GeoJSON, GeoJSONOptions, Layer, LayerGroup, Map, MapOptions, TileLayer} from 'leaflet';
import * as L from 'leaflet';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CustomFormatService} from "../services/format/custom-format.service";

interface Locality {id: number; code: string; name: string; idOsm: string; }
interface DefaultType {id: number; name: string; color: string; }
interface LocalMap extends MapOptions {
  fullscreenControl: any;
}
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  private PATH = 'home';
  mapInfo: {
    budget: string,
    localities: string,
    project: string,
    currency: string,
    details: {
      agencies: any,
      financing: any,
      locations: any,
      sectors: any
    }
  } | undefined;
  loading = false;
  private _sectors: DefaultType[] = [];
  private _agencies: DefaultType[] = [];
  private _financing: DefaultType[] = [];
  statusList: {id: number, name: string, state: boolean}[];
  private historicalLocalities: Locality[] = [];
  private _localities: Locality[] = [];
  private mapView: Map | undefined;
  private layerGroupEdit: LayerGroup | undefined;
  private selectedMapLegend = 1;
  private layerGroup: LayerGroup | undefined;
  private layerCities: any;
  private legendControl: any;
  private globalMapData: {code: string, gps: {lat: number, lng: number}, id: number,  label: string,  projects: any, size: number, type: number, uid: number}[] = [];
  public selectedSector: DefaultType | undefined;
  public selectedAgency: DefaultType | undefined;
  public selectedFinancing: DefaultType | undefined;
  private translated: any = {};
  geographicLevel: any;

  public showPanel = true;
  public streetview: TileLayer;



  constructor(private http: HttpService, private translate: TranslateService, private customFormatService: CustomFormatService) {
    this.statusList = [
      {id: 1, name: 'HOME.STATUS.NOT_STARTED', state: false},
      {id: 2, name: 'HOME.STATUS.IN_PROGRESS', state: true},
      {id: 3, name: 'HOME.STATUS.PENDING', state: false},
      {id: 4, name: 'HOME.STATUS.COMPLETED', state: false}, ];

    this.translate.get('MAP').subscribe(data => {
      this.translated = data;
      // console.log(this.translated);
    });


  }


  generateGradient(fromColor: number[] = [39, 253, 210], toColor: number[] = [12, 76, 63]) {
    const pad = (n: string, width: number, z: string = '0') => {
      z = z || '0';
      // n = String(n);
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    };

    const colors = [
      // somewhere to dump gradient
    ];
    // the number of steps in the gradient
    const stepsInt = 100;
    // the percentage representation of the step
    const stepsPerc = 100 / (stepsInt + 1);

    // diffs between two values
    const valClampRGB = [
      toColor[0] - fromColor[0],
      toColor[1] - fromColor[1],
      toColor[2] - fromColor[2]
    ];

    // build the color array out with color steps
    for (let i = 0; i < stepsInt; i++) {
      const clampedR = (valClampRGB[0] > 0) ?
        pad((Math.round(valClampRGB[0] / 100 * (stepsPerc * (i + 1)))).toString(16), 2) :
        pad((Math.round((fromColor[0] + (valClampRGB[0]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

      const clampedG = (valClampRGB[1] > 0) ?
        pad((Math.round(valClampRGB[1] / 100 * (stepsPerc * (i + 1)))).toString(16), 2) :
        pad((Math.round((fromColor[1] + (valClampRGB[1]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);

      const clampedB = (valClampRGB[2] > 0) ?
        pad((Math.round(valClampRGB[2] / 100 * (stepsPerc * (i + 1)))).toString(16), 2) :
        pad((Math.round((fromColor[2] + (valClampRGB[2]) / 100 * (stepsPerc * (i + 1))))).toString(16), 2);
      colors[i] = [
        '#',
        clampedR,
        clampedG,
        clampedB
      ].join('');
    }
    return colors;
  }

  /*
  Manage Map
   */
  changeLegend(maxValue = 100) {
    if (this.legendControl && this.mapView) {
      this.mapView.removeControl(this.legendControl);
    }

    this.legendControl = new Control({
      position: 'bottomright',

    });

    const colorGradient = this.generateGradient();

    const step = this.selectedMapLegend == 1 ? 20 : 200;
    const translated = this.translated;
    this.legendControl.onAdd = (() => {
      const div = L.DomUtil.create('div', 'info white-bg p-xs map-legend');
      // loop through our density intervals and generate a label with a colored square for each interval
      const index_ = 0;
      div.innerHTML += '<div class="bar"></div>';

      let divValue = '';
      for (let i = 0; i <= 5; i++){
        divValue += `<div class="value-item">${(i * step) || 1}${i == 5 ? '+' : ''}</div>`;
      }
      div.innerHTML += `<div class="values">${divValue}</div>`;

      div.innerHTML = `<div style="display: flex">
                      <h5 id="legend_project" data-value="1" class="legend_label  ${this.selectedMapLegend == 1 ? 'active' : ''}  ">
                        ${translated.NUMBER_PROJECT}
                      </h5>
                      <div style="flex: 1"></div>
                      </div>
                      <div class="gradiant">${div.innerHTML}</div>`;

      return div;
    });

    this.legendControl.addTo(this.mapView);
  }
  private getMapUrl(value: number): string {
    let mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
    switch (value) {
      case 0:
        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjnem4vy029oz2sphj8jvnp9a/tiles/256/{z}/{x}/{y}';
        break;
      case 1:
        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvs9jqk60fr91cscf0w98zth/tiles/256/{z}/{x}/{y}@2x';
        break;
      case 2:
        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbh28818ic1cmqcfaz1is2/tiles/256/{z}/{x}/{y}@2x';
        break;
      case 3:
        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cjvsbjkcx0mkz1cp3ot9cdfnk/tiles/256/{z}/{x}/{y}@2x';
        break;
      case 4:
        mapstr = 'https://api.mapbox.com/styles/v1/bksb/ck0wcr1og05gz1cq7ik9y0ct5/tiles/256/{z}/{x}/{y}@2x';
        break;
      default:
        mapstr = 'https://api.mapbox.com/styles/v1/bksb/cj8x1v3iv05pz2rrtc9ps1tui/tiles/256/{z}/{x}/{y}';
        break;
    }
    return mapstr + '?access_token=pk.eyJ1IjoiYmtzYiIsImEiOiJjaXQ5ZHlpM2gwMDJvMnNwaDAxZXFsbndrIn0.0W3tcj-HtlRSCJDcuxNRew';
  }



  private initialiseMap(){
    this.streetview = new TileLayer(this.getMapUrl(4));

    const params: LocalMap = {
      fullscreenControl: {
        title: {
          false: 'Afficher en plein ecran',
          true: 'Sortir du plein ecran'
        }
      },
      layers: [this.streetview]
    };

    this.mapView = new Map('mapView', params).setView([14.497401, -14.452362], 8);

    this.layerGroupEdit = new LayerGroup([], {
      attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
    }).addTo(this.mapView);

    (new TileLayer( this.getMapUrl(4))).addTo(this.mapView);

    const grayscale = new TileLayer(this.getMapUrl(1));
    const base: any = {
      'Carte en niveau de gris': grayscale,
      'Street Map': new TileLayer(this.getMapUrl(4)),
      Satellite: new TileLayer(this.getMapUrl(3)),

    };
    base['Carte en niveau de gris'] = grayscale;
    base['Street Map'] = new TileLayer(this.getMapUrl(4));
    base.Satellite = new TileLayer(this.getMapUrl(3));


    // @ts-ignore
    // (new Control({position: "topright"})).layers(base).addTo(this.mapView);



    L.control.layers(base).addTo(this.mapView);

    this.changeLegend(this.selectedMapLegend == 2 ? 1000 : 100);

    this.layerGroup = new LayerGroup([], {
      attribution: '© <a href=\'https://www.mapbox.com/about/maps/\'>Mapbox</a> , © <a href=\'https://www.openstreetmap.org/copyright\'>OpenStreetMap</a>  <strong><a href=\'https://www.mapbox.com/map-feedback/\' target=\'_blank\'>Improve this map</a></strong>'
    });

    this.layerGroup.addTo(this.mapView);

    this.layerCities = new LayerGroup([]);
    this.layerCities.addTo(this.mapView);

  }

  private drawLocationLayers(): void{
    if (!this.layerGroup){
      return;
    }
    this.layerGroup.clearLayers();

    this.changeLegend();

    const legendItems = document.getElementsByClassName('legend_label');

    for (let index = 0; index < legendItems.length; index++){
      const legendItem = legendItems.item(index);

      if (legendItem){
        const value = parseInt(legendItem.getAttribute('data-value') || '1', 10);
        if (value === this.selectedMapLegend){
          continue;
        }

        legendItem.addEventListener('click', (event) => {
          this.selectedMapLegend = value;
          this.changeLegend();
          this.drawLocationLayers();
        });
      }

    }

    this.loading = true;

    if (this.localities.length == 0){
      if(this.historicalLocalities.length == 0){
        this.loading = false;
        return;
      }
      return this.changeLocality();
    }

    this.translate.get('MAP').subscribe(data => {
      this.translated = data;
    });

    this.http.getNative(  `https://map.delta-monitoring.com/locations/${this.locationLevel}/${this.localities.map(l => (l.idOsm)).join(',')}/format=geojson`)
      .subscribe((data: any) =>
      {
        this.loading = false;
        const localities: any = {};

        const translated = this.translated;

        for (const locality of this.localities){
          localities[locality.idOsm] = locality;
        }
        const dataLocalities: any = this.mapInfo ? this.mapInfo.details.locations : {};
        const maxValue = this.selectedMapLegend == 1 ? 100 : 1000000000;
        const key = this.selectedMapLegend == 1 ? 'COUNT' : 'AMOUNT';
        const selectedMapLegend = this.selectedMapLegend;

        const colorGradient = this.generateGradient();

        const currency = this.mapInfo ? this.mapInfo.currency : '';

        if (!this.layerGroup){
          return;
        }

        (new GeoJSON(data, {
          pointToLayer(point, latlng) {
            return L.circle(latlng, {radius: 15000});
          },
          onEachFeature(feature, layer) {
            const child = localities[feature.id || 0];
            if (!child){
              return;
            }
            layer.on({
              add(event) {
                event.target.bringToBack();
              }
            });
            layer.bindTooltip(() => {
              return `<div  class="p-xs">
            <h5> &#128681; ${child.name}</h5>
            <div class="text-right">
            <span> ${selectedMapLegend == 1 ? (translated.PROJECT)+ ' : ' + Math.round(dataLocalities[child.code][key]).toString() : Math.round(dataLocalities[child.code][key]).toString() + ' ' + (' ( millions ' +  currency + ')')} </span>
            </div>
            </div>`;
            });
          },
          style(feature) {
            if (!feature){
              return {};
            }
            if (!feature.properties.color){
              const child = localities[feature.id || 0];

              if (!child){
                return {};
              }

              const value = Math.round((dataLocalities[child.code] || {COUNT: 0, AMOUNT: 0})[key] / (maxValue / 100));
              const searchIndex = (value > 99 ? 99 : (value < 1 ? 0 : value));
              feature.properties.color = colorGradient[searchIndex];
            }
            return {
              fillColor: feature ? feature.properties.color : 'transparent',
              weight: 2,
              opacity: 1,
              color: feature ? feature.properties.color : 'transparent',
              dashArray: '3',
              fillOpacity: 0.1
            };
          }
        })).addTo(this.layerGroup);

      }, () => {
        this.loading = false;
      });
  }

  selectSector(item: DefaultType){
    if (item == this.selectedSector){
      this.selectedSector = undefined;
    } else {
      this.selectedSector = item;
    }
    this.displayProjects();
  }

  changeStatus(){
    this.projectMapInfo().subscribe(data => {
      this.drawLocationLayers();
    });
  }

  selectFinancing(item: DefaultType){
    if (item == this.selectedFinancing){
      this.selectedFinancing = undefined;
    } else {
      this.selectedFinancing = item;
    }
    this.displayProjects();
  }

  selectAgency(item: DefaultType){
    if (item == this.selectedAgency){
      this.selectedAgency = undefined;
    } else {
      this.selectedAgency = item;
    }
    this.displayProjects();
  }


  //RECUPERATION DES INFOS SUR LA LOCALITE
  private displayProjects(){
    this.layerCities.clearLayers();
    const code = this.selectedLocality ? this.selectedLocality.code : '';
    // Les Localites
    let data = [];
    for (const line of this.globalMapData.filter(locality => locality.code.startsWith(code))) {
      for (const key in line.projects){
        const project = line.projects[key];
        data.push({
          id: project.id,
          label: project.label,
          color: project.color,
          amount: project.amount,
          sector: project.idSector,
          formatcout: project.Format_cout,
          objectif: project.objectif,
          gps: line.gps,
          locality: line.label,
          position: project.position
        });
      }
    }

    // secteurs
    if (this.selectedSector && this.mapInfo){
      const sectorData = this.mapInfo.details.sectors[this.selectedSector.id];
      if (sectorData){
        data = data.filter(project => {
          if ((this.selectedSector ? this.selectedSector.id : 0) != project.sector){
            return false;
          }
          return sectorData.PRJ.indexOf(project.id) >= 0;
        });
      }
    }
    // source de financement
    if (this.selectedFinancing && this.mapInfo){
      const financingData = this.mapInfo.details.financing[this.selectedFinancing.id];
      if (financingData){
        data = data.filter(project => {
          return financingData.PRJ.indexOf(project.id) >= 0;
        });
      }
    }
    // Agences
    if (this.selectedAgency && this.mapInfo){
      const agenciesData = this.mapInfo.details.agencies[this.selectedAgency.id];
      if (agenciesData){
        data = data.filter(project => {
          return agenciesData.PRJ.indexOf(project.id) >= 0;
        });
      }
    }

    let bounds = null;

    let minAmount = 999999999999
    let maxAmount = 0

    for (const project of data){
      if(parseFloat(project.amount) > 0){
        minAmount = Math.min(parseFloat(project.amount), minAmount);
        maxAmount = Math.max(parseFloat(project.amount), maxAmount);
      }
    }

    for (const project of data){
      //console.log('Projet infos list : '+ JSON.stringify(project));
      const marker =   L.geoJSON({
        type: 'Feature',
        // @ts-ignore
        properties: {
          locality: project.locality,
          project: project.label,
          id: project.id,
          formatcout: project.formatcout,
          objectif: project.objectif,
          amount: parseFloat(project.amount)
        },
        geometry: {
          type: 'Point',
          coordinates: [project.gps.lat - (project.position * 0.0005), project.gps.lng - (project.position * -0.001)]
        }
      }, {
        pointToLayer(point, latlng) {
          return L.circleMarker(latlng, {color: 'rgb(' + project.color + ')',  fill: true,
            radius: project.amount == 0 ? 1 :
              (maxAmount == minAmount ? 15 : (15 + (10 *  (project.amount - minAmount)/ (maxAmount - minAmount))))
          });
        }
      }).bindTooltip((layer: any) => {
        //TOOLTIP INFOS PROJET
        //console.log('Projet infos : '+ JSON.stringify(layer.feature.properties));
        return `<div class="tooltip-project">
            <div class="text-ellipsis--3">  <strong><b> ${layer.feature.properties.project} </b> </strong></div>
            <div class="text-ellipsis--3">
                  &#128681;  ${layer.feature.properties.locality}
            </div>
            <div class="text-ellipsis--3">
            <strong><b>${this.translated.BUDGET} :</b> </strong> ${ this.customFormatService.formatNumber(layer.feature.properties.amount)} ${layer.feature.properties.formatcout}
            </div>
            <div class="text-ellipsis--3">
            <strong><b>${this.translated.IMPACT} :</b> </strong> <br>${layer.feature.properties.objectif}
            </div>
          </div>
          <style>
          .text-ellipsis--3{
            text-overflow:ellipsis;
            overflow:hidden;
            // Addition lines for 3 line or multiline ellipsis
            display: -webkit-box !important;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
          .tooltip-project{
            width : 30vw
          }
          </style>

          `;

      });
      marker.addTo(this.layerCities);
      if (bounds) {
        bounds.extend(marker.getBounds());
      } else {
        bounds = marker.getBounds();
      }
    }
    if (bounds != null && this.mapView) {
      this.mapView.fitBounds(bounds);
    }

  }

  /*
  Manage Filter
   */

  get selectedLocality(): Locality | undefined{
    if (this.historicalLocalities.length === 0 ){
      return undefined;
    }
    return this.historicalLocalities[this.historicalLocalities.length - 1];
  }

  get localities(): Locality[]{
    return this._localities.filter(locality => {
      if (this.mapInfo === undefined){
        return false;
      }
      return this.mapInfo.details.locations[locality.code] !== undefined;
    });
  }

  get agencies(): DefaultType[]{
    return this._agencies.filter(agency => {
      if (this.mapInfo === undefined){
        return false;
      }
      return this.mapInfo.details.agencies[agency.id] !== undefined;
    });
  }

  get financing(): DefaultType[]{
    return this._financing.filter(finance => {
      if (this.mapInfo === undefined){
        return false;
      }
      return this.mapInfo.details.financing[finance.id] !== undefined;
    });
  }

  get sectors(): DefaultType[]{
    return this._sectors.filter(sector => {
      if (this.mapInfo === undefined){
        return false;
      }
      return this.mapInfo.details.sectors[sector.id] !== undefined;
    });
  }

  changeLocality(locality?: Locality): void{
    if (locality === undefined){
      this.historicalLocalities.pop();
    } else {
      if (this.geographicLevel.isLast){
        return;
      }
      this.historicalLocalities.push(locality);
    }
    this.projectMapInfo().subscribe(value => {

      if (value){
        this.displayProjects();
        this.getSubLocation();
      }
    });
  }

  projectMapInfo(): Observable<boolean>{
    const param: any = {get: 'map_info', loc: ''};

    if (this.selectedLocality){
      param.loc = this.selectedLocality.code;
    }

    const status = this.statusList.filter(value => {
      return value.state;
    }).map(value => (value.id)).join(' , ');

    if (status !== ''){
      param.status = status;
    }

    const subject = new Subject<boolean>();
    this.http.get(this.PATH, param ).subscribe(data => {

      this.mapInfo = {
        budget: this.customFormatService.formatNumber( Math.round(data.BUDGET / 1000000)),
        localities: this.customFormatService.formatNumber(data.LOC),
        project: this.customFormatService.formatNumber(data.PRJ),
        currency: data.CUR,
        details: {
          agencies: data.agencies,
          financing: data.financing,
          locations: data.location,
          sectors: data.sectors
        }
      };
      subject.next(true);
      subject.complete();
    });

    return subject;

  }

  private get locationLevel(): number{
    if (!this.selectedLocality){
      return 0;
    }
    return this.historicalLocalities.length;
  }
  private getSubLocation(): void{
    const params: any = {get: 'sub_location'};

    if (this.selectedLocality !== undefined){
      params.id = this.selectedLocality.id;
    }

    this.http.get(this.PATH, params).subscribe(data => {
      this._localities = data.localities;
      this.geographicLevel = data.level;
      this.drawLocationLayers();
    });
  }

  ngOnInit(): void {
    this.initialiseMap();
    this.http.get(this.PATH, {get: 'map_data'} ).subscribe(data => {
      this._sectors = data.sectors;
      this._agencies = data.agencies;
      this._financing = data.financing;
    });

    // map_info_locality

    this.http.get(this.PATH, {get: 'map_info_locality'}).subscribe(data => {
      this.globalMapData = data;
    });

    this.changeLocality();

  }

  toggleFilterPanel() {
    this.showPanel = !this.showPanel;
  }
}
