
<div class="d-sm-flex align-items-center justify-content-between  message-defil">
  <!-- le conteneur fenêtre -->
  <div class="marquee-rtl" *ngIf="nbreBanner>0">
    <!-- le contenu défilant -->
    <div>
        <span *ngFor="let banner of dataBanner"> <span class="info-cercle">&#8520;</span> {{banner.DESCRIPTION_BANNER}} <span class="ml" ></span></span>
    </div>

  </div>
</div>

<div class="main-content">
  <div></div>
  <div class="information">

    <div class="space"></div>
    <div>
      <div  class="logo">
        <img class="img img-fluid" style="width: 260px" src="./assets/img/logo_final.png"> <br>
       <!-- <span style=" font-size: 7em; color: #4b9d58;"> <b> <strong> SIGGI </strong> </b> </span> <br><br>-->
      </div>
      <div class="project">
        <div>
          <div> {{'HOME.PROJECT_1' | translate}}</div>
          <br>
          <div> {{'HOME.PROJECT_2' | translate}}</div>
        </div>

        <div class="">
          <br><br>
        </div>
      </div>
      <!-- <div class="description">
        <div> {{'HOME.DESCRIPTION_1' | translate}}</div>
        <div> {{'HOME.DESCRIPTION_2' | translate}}</div>
      </div> -->
    </div>
    <div class="space"></div>
  </div>
  <div></div>
  <div class="map" >

    <div style="display: flex;">
      <div class="space"><img class="img img-fluid" src="./assets/img/map0.png" alt="Senegal Map"></div>
      <div class="space"><img class="img img-fluid" src="./assets/img/map1.png" alt="Senegal Map"></div>
      <div class="space"><img class="img img-fluid" src="./assets/img/map2.png" alt="Senegal Map"></div>
    </div>

    <div style="display: flex;">
      <img class="img img-fluid" src="./assets/img/map_2.png" alt="Senegal Map">
    </div>

  </div>
  <div></div>
</div>

<app-login *ngIf="key" [key] ="key"></app-login>
