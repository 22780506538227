<div class="main-content">

  <div class="left-panel" *ngIf="showPanel">
    <div class="filter-panel">
      <div class="close-panel">

        <span (click)="toggleFilterPanel()">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
            class="bi bi-chevron-double-left" viewBox="0 0 22 22">
            <path fill-rule="evenodd"
              d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
            <path fill-rule="evenodd"
              d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
          </svg>
        </span>

      </div>
      <div *ngIf="selectedLocality" class="active-locality">
        <div class="back">
          <button (click)="changeLocality()">
            <-
          </button>
        </div>
        <h2 class="location">
          {{selectedLocality.name}}
        </h2>

      </div>

      <!-- FILTRES-->
      <div class="filter-panel-content" [class]="selectedLocality ? 'has-location' : ''">
        <div class="stats">
          <div class="value-panel">
            <div class="label">{{'MAP.PROJECT' | translate}}</div>
            <div class="value">{{mapInfo?.project }}</div>
          </div>
          <!--
            <div class="amount value-panel">
              <div class="label">{{'MAP.COST' | translate: {devis: mapInfo?.currency} }}</div>
              <div class="value">{{mapInfo?.budget }}</div>
            </div>


            <div  class="value-panel">
              <div class="label">{{'MAP.LOCALITIES' | translate}}</div>
              <div class="value">{{mapInfo?.localities }}</div>

            </div>
            -->
        </div>

        <!-- Statut des projets -->
        <div class="status section" *ngIf="false">
          <h5 class="title">{{'MAP.STATUS' | translate}}</h5>
          <div>
            <div class="form-check form-check-inline" *ngFor="let statusItem of statusList">

              <label class="form-check-label " [class]="statusItem.state? 'checked' : ''"
                for="inlineCheckbox{{statusItem.id}}">
                <input class="form-check-input" type="checkbox" (change)="changeStatus()"
                  id="inlineCheckbox{{statusItem.id}}" [(ngModel)]="statusItem.state">
                {{statusItem.name | translate}}</label>
            </div>
          </div>

        </div>

        <div class="sector section">
          <h5 class="title">{{'MAP.SECTORS' | translate}}</h5>
          <div>
            <ul class="list-group">

              <!--lISTE SECTEURS-->
              <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let item of sectors"
                [class]="selectedSector == item? 'active' : '' " (click)="selectSector(item)">
                <span class="color" [ngStyle]="{background: 'rgb('+item.color + ')'}"></span>
                <span class="label">
                  {{item.name | lowercase}}
                </span>
                <span class="value" *ngIf="mapInfo">
                  ({{mapInfo.details.sectors[item.id].COUNT}})
                </span>
              </li>

            </ul>
          </div>
        </div>

        <div class="financial-source section">
          <h5 class="title">{{'MAP.FUNDING' | translate}}</h5>
          <div>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                *ngFor="let item of financing" [class]="selectedFinancing == item? 'active' : '' "
                (click)="selectFinancing(item)">
                <span class="label">
                  {{item.name}}
                </span>
                <span class="value" *ngIf="mapInfo">
                  ({{mapInfo.details.financing[item.id].COUNT}})
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="agencies section">
          <h5 class="title">{{'MAP.AGENCIES' | translate}}</h5>
          <div>

            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                *ngFor="let item of agencies" [class]="selectedAgency == item? 'active' : '' "
                (click)="selectAgency(item)">
                <span class="label">
                  {{item.name}}
                </span>
                <span class="value" *ngIf="mapInfo">
                  ({{mapInfo.details.agencies[item.id].COUNT}})
                </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="sub-locations section">
          <h5 class="title" *ngIf="geographicLevel">{{geographicLevel.label}}</h5>
          <div>
            <ul class="list-group">
              <li class="list-group-item d-flex justify-content-between align-items-center"
                *ngFor="let item of localities">
                <span class="label" (click)="changeLocality(item)">
                  {{item.name}}
                </span>
                <span class="value" *ngIf="mapInfo">
                  ({{mapInfo.details.locations[item.code].COUNT}})
                </span>
              </li>
            </ul>
          </div>
        </div>

      </div>

    </div>
  </div>

  <div *ngIf="!showPanel" class="screen_devise">
    <span (click)="toggleFilterPanel()" style="cursor: pointer;">
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor"
        class="bi bi-chevron-double-right" viewBox="0 0 22 22">
        <path fill-rule="evenodd"
          d="M3.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L9.293 8 3.646 2.354a.5.5 0 0 1 0-.708z" />
        <path fill-rule="evenodd"
          d="M7.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L13.293 8 7.646 2.354a.5.5 0 0 1 0-.708z" />
      </svg>
    </span>
  </div>



  <div class="map">
    <div id="mapView">

    </div>
  </div>
</div>

<div class="loading" *ngIf=" loading">
  <div class="spinner spinner-border text-success" role="status">
    <span class="sr-only">Login...</span>
  </div>
</div>
