<div  class="message">
    <span >
        <!-- <fa-icon [icon]="faHome" style="margin: 0 5px 0 0"></fa-icon>   -->
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path  d="M12 1.5C9.81275 1.50258 7.71583 2.3726 6.16921 3.91922C4.6226 5.46584 3.75257 7.56276 3.74999 9.75C3.74737 11.5374 4.33123 13.2763 5.41199 14.7C5.41199 14.7 5.63699 14.9963 5.67374 15.039L12 22.5L18.3292 15.0353C18.3622 14.9955 18.588 14.7 18.588 14.7L18.5887 14.6978C19.669 13.2747 20.2526 11.5366 20.25 9.75C20.2474 7.56276 19.3774 5.46584 17.8308 3.91922C16.2842 2.3726 14.1872 1.50258 12 1.5ZM12 12.75C11.4066 12.75 10.8266 12.5741 10.3333 12.2444C9.83994 11.9148 9.45542 11.4462 9.22835 10.8981C9.00129 10.3499 8.94188 9.74667 9.05764 9.16473C9.17339 8.58279 9.45912 8.04824 9.87867 7.62868C10.2982 7.20912 10.8328 6.9234 11.4147 6.80764C11.9967 6.69189 12.5999 6.7513 13.148 6.97836C13.6962 7.20542 14.1648 7.58994 14.4944 8.08329C14.824 8.57664 15 9.15666 15 9.75C14.999 10.5453 14.6826 11.3078 14.1202 11.8702C13.5578 12.4326 12.7953 12.749 12 12.75Z" fill="white"></path></svg>
        2, AVENUE JEANNE, 92604 ASNIERES SUR SEINE CEDEX - FRANCE
    </span>

    <span >
      <a href="tel:+33146889370" target="_blank">
        <!-- <fa-icon [icon]="faPhone" style="margin: 0 0 0 5px"></fa-icon>  -->
        <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path  d="M18 9.75H16.5C16.4994 9.15345 16.2622 8.5815 15.8403 8.15967C15.4185 7.73784 14.8466 7.5006 14.25 7.5V6C15.2442 6.00119 16.1973 6.39666 16.9003 7.09966C17.6033 7.80267 17.9988 8.7558 18 9.75Z" fill="white"></path><path  d="M21 9.75H19.5C19.4984 8.3581 18.9448 7.02367 17.9606 6.03944C16.9763 5.05522 15.6419 4.50159 14.25 4.5V3C16.0396 3.00199 17.7553 3.71378 19.0208 4.97922C20.2862 6.24466 20.998 7.9604 21 9.75Z" fill="white"></path><path  d="M15.2498 16.1115L16.9298 14.4315C17.1546 14.2066 17.4406 14.0526 17.7521 13.9886C18.0637 13.9246 18.3872 13.9534 18.6825 14.0715L20.7285 14.8905C21.0301 15.0112 21.2886 15.2194 21.4706 15.4884C21.6527 15.7573 21.75 16.0747 21.75 16.3995V20.1203C21.7505 20.3414 21.7059 20.5603 21.6189 20.7636C21.5319 20.9669 21.4043 21.1504 21.2439 21.3026C21.0836 21.4549 20.8938 21.5729 20.6863 21.6493C20.4788 21.7257 20.2578 21.7589 20.037 21.747C5.69551 20.8545 2.79901 8.70751 2.26126 4.05601C2.23522 3.8286 2.25761 3.59826 2.32697 3.38013C2.39634 3.16201 2.51109 2.96104 2.66369 2.79044C2.81629 2.61984 3.00328 2.48349 3.21236 2.39034C3.42144 2.29719 3.64788 2.24937 3.87676 2.25001H7.53151C7.85632 2.25 8.17367 2.3473 8.44264 2.52938C8.71161 2.71146 8.91986 2.96995 9.04051 3.27151L9.85876 5.31751C9.97685 5.61284 10.0057 5.93633 9.94169 6.24788C9.8777 6.55944 9.72369 6.84538 9.49876 7.07026L7.81876 8.75026C7.81876 8.75026 8.75026 15.2993 15.2498 16.1115Z" fill="white"></path></svg>
        Tél : +33 1 46 88 93 70
      </a>
    </span>

    <span >
      <fa-icon [icon]="faGlobe" style="margin: 0 5px 0 0"></fa-icon>
      <a href="https://sossahel.org"  target="_blank">
        WWW.SOSSAHEL.ORG
      </a>
    </span>

    <span >
      <!-- <fa-icon [icon]="faGlobe" style="margin: 0 5px 0 0"></fa-icon> -->
      <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path  d="M22 8.608V16.75C22.0001 17.5801 21.6824 18.3788 21.1123 18.9822C20.5422 19.5856 19.7628 19.948 18.934 19.995L18.75 20H5.25C4.41986 20.0001 3.62117 19.6824 3.01777 19.1123C2.41437 18.5422 2.052 17.7628 2.005 16.934L2 16.75V8.608L11.652 13.664C11.7594 13.7202 11.8788 13.7496 12 13.7496C12.1212 13.7496 12.2406 13.7202 12.348 13.664L22 8.608ZM5.25 4H18.75C19.5556 3.9999 20.3325 4.299 20.93 4.83927C21.5276 5.37954 21.9032 6.12248 21.984 6.924L12 12.154L2.016 6.924C2.09352 6.15431 2.44305 5.43752 3.00175 4.90246C3.56045 4.36741 4.29168 4.04919 5.064 4.005L5.25 4H18.75H5.25Z" fill="white"></path></svg>
      <a href="mailto:contact@sossahel.org"  target="_blank">
        CONTACT@SOSSAHEL.ORG
      </a>
    </span>

</div>
