import {Component, OnInit, ViewChildren, QueryList, ViewChild, AfterViewInit, ElementRef} from '@angular/core';
import {SortableHeaderDirective} from '../directive/sortable-header.directive';
import {HttpService} from '../services/http/http.service';
import { MatTableDataSource } from '@angular/material/table';
//FOR FROM SEARCH
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {  BehaviorSubject, combineLatest, of } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {TranslateService} from "@ngx-translate/core";
import {formatDate} from "@angular/common";

interface Document {
  author: string;
  dateModified: string;
  id: number;
  isFile: boolean;
  name: string;
  theme: string;
  type_doc: string;
  type: string;

}

export type SortColumn = keyof Document | '';
export type SortDirection = 'asc' | 'desc' | '';

const compare = (v1: string | number | boolean, v2: string | number | boolean) => v1 < v2 ? -1 : v1 > v2 ? 1 : 0;

export interface SortEvent {
  column: SortColumn;
  direction: SortDirection;
}

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent implements OnInit, AfterViewInit {

  //FOR FORM SEARCH
  myControlTitle = new FormControl();
  optionsTitle: string[] = [];
  filteredOptions: Observable<string[]>;
  name: string;
  dateModified: string;
  author: string;
  type_doc: string;
  theme: string;
  allSourcefin: string;
  allSector: string;
  dataSourceDocs: Document[] = [];

  tableHeader = [
    { key: "type" },
    { key: "name" },
    { key: "dateModified" },
    { key: "author" },
    { key: "type_doc" },
    { key: "theme" },
    { key: "allSourcefin" },
    { key: "allSector" },
    { key: "download" },
  ];
  filterKeyValues = this.tableHeader.map(({ key }) => ({ key, value: "" }));
  filterSubject$ = new BehaviorSubject(this.filterKeyValues)
  filter$ = this.filterSubject$.asObservable();

  //END

  documents: Document[] = [];

  private baseDocuments: Document[] = [];
  itemsByPage = 10;
  pages: number[] = [];
  currentPage = 1;


  @ViewChildren(SortableHeaderDirective) headers: QueryList<SortableHeaderDirective> = new QueryList();
  @ViewChild('content') content: ElementRef | undefined;
  loading  = true;



  constructor(private http: HttpService, private translate: TranslateService) {


    // Object to create Filter for
    this.filterSelectObj = [
      {name: '', columnProp: 'type',options: [] },
      {name: ' ', columnProp: 'name',options: [] },
      {name: 'Date', columnProp: 'dateModified',options: [] },
      {name: ' ', columnProp: 'author',options: [] },
      {name: ' ', columnProp: 'type_doc',options: [] },
      {name: ' ', columnProp: 'theme',options: [] },
      {name: ' ', columnProp: 'allSourcefin',options: [] },
      {name: ' ', columnProp: 'allSector',options: [] },
      {name: ' ', columnProp: 'download',options: [] },

    ];


  }

  onSort({column, direction}: SortEvent): void{
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    // sorting countries
    if (direction === '' || column === '') {
      this.documents = this.baseDocuments;
    } else {
      this.documents = [...this.baseDocuments].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }


  }

  get documentView(): Document[]{
    return this.documents.slice((this.currentPage - 1) * this.itemsByPage, this.currentPage  * this.itemsByPage);
  }

  get dataSourceDocs$(){
    //FILTRES
    return combineLatest([
      this.filter$, of(this.documentView)
    ]).pipe(
      map(([filter, dataSourceDocs]) =>
        dataSourceDocs.filter(item => filter.every((value) => {
          if(value.key === 'dateModified'){


            const currentDate = formatDate(
              item[value.key], 'mediumDate',
              this.translate.currentLang + '-'+this.translate.currentLang.toUpperCase()
            ).toUpperCase();
  /*
            const currentDate = item[value.key].toString().split('-').reverse();

            if(this.translate.currentLang === 'en'){
              const tmp = currentDate[0];
              currentDate[0] = currentDate[1];
              currentDate[1] = tmp;
            }
*/

            return currentDate.startsWith(value.value.toString().toUpperCase());

          }

             return (new RegExp(String(value.value).toLowerCase())).test(String(item[value.key]).toLowerCase());
           })
        )
      )
    );
  }

  downloadItem(document: Document): void{

    this.http.get('home', {get: 'single_document', simple: true, id: document.id}).subscribe(item => {
        if (item.TYPE_RAPPORT.toString() === '2') {
          window.open(item.CHEMIN_RAPP, '_blank');
          return;
        }

        this.http.get('home', {download: true, c: item.Reference, id: item.id}).subscribe(data => {
          if (data.f !== '') {
            window.open(this.http.resources + data.f, '_blank');
          }
        });
    });

  }

  ngOnInit(): void {
    this.getRemoteData();
    // Overrride default filter behaviour of Material Datatable
    this.dataSource.filterPredicate = this.createFilter();
  }

  ngAfterViewInit(): void {

    if (this.content !== undefined){
      // let size = this.content.nativeElement.offsetHeight - 100;
      // this.itemsByPage = (( size - (size % 60)) / 60) - 2;
      const screenHeight = window.innerHeight - 300;
      this.itemsByPage = (( screenHeight - (screenHeight % 60)) / 60) - 3;
      if (this.itemsByPage <= 0) {
        this.itemsByPage = 7;
      }
    }

    this.http.get('home', {get: 'documents'}).subscribe(data => {
      // console.log(data);
      // data=[];
      this.baseDocuments = data;
      this.documents = [...data];
      this.dataSourceDocs = this.documents;
      // console.log(this.documents);
      this.loading = false;
      let size = 0;
      // console.log(this.itemsByPage, this.currentPage);
      while (size < this.documents.length){
        this.pages.push(this.pages.length  + 1);
        size += this.itemsByPage;
      }
      // console.log(this.dataSourceDocs$);
    });

    //FOR FORM SEARCH (FILTRE)
    this.filteredOptions = this.myControlTitle.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value,this.optionsTitle)),
    );



  }

  //FOR FORM SEARCH
  private _filter(value: string, options: string[]): string[] {
    const filterValue = (value || '').toString().toLowerCase();
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  public inputChange(event: any, key) {


    this.filterKeyValues.find(({key: testKey}) => testKey === key).value = event.target.value;

    this.filterSubject$.next(this.filterKeyValues);

  }
  //END





  //filtre
  filterSelectObj = [];
  dataSource = new MatTableDataSource();
  displayedColumns: string[] = ['type', 'name', 'dateModified', 'author', 'type_doc', 'theme', 'allSourcefin', 'allSector', 'download'];
  filterValues = {};
  // Called on Filter change
  filterChange(filter, event) {
    //let filterValues = {}
    this.filterValues[filter.columnProp] = event.target.value.trim().toLowerCase()
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }
  // Custom filter method fot Angular Material Datatable
  createFilter() {
    let filterFunction = function (data: any, filter: string): boolean {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      for (const col in searchTerms) {
        if (searchTerms[col].toString() !== '') {
          isFilterSet = true;
        } else {
          delete searchTerms[col];
        }
      }


      let nameSearch = () => {
        let found = false;
        if (isFilterSet) {
          for (const col in searchTerms) {
            searchTerms[col].trim().toLowerCase().split(' ').forEach(word => {
              if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                found = true
              }
            });
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }
  // Reset table filters
  resetFilters() {
    this.filterValues = {}
    this.filterSelectObj.forEach((value, key) => {
      value.modelValue = undefined;
    })
    this.dataSource.filter = "";
  }
  // Get Uniqu values from columns to build filter
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }

  // Get remote serve data using HTTP call
  getRemoteData() {

    const remoteDummyData = this.documents.slice((this.currentPage - 1) * this.itemsByPage, this.currentPage  * this.itemsByPage);

    this.dataSource.data = remoteDummyData;

    this.filterSelectObj.filter((o) => {
      o.options = this.getFilterObject(remoteDummyData, o.columnProp);
    });


  }


  //filtre





}
